import {
  TipSettings,
  Preset as SettingsTipPreset,
} from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import { AmountType, Preset, PresetType, SelectedAmount } from '../../types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { generateUUID } from '..';
import { ActionsContext } from '../../core/actionsContext/contextFactory';
import {
  UpdateTipChoiceRequest,
  TipType,
} from '@wix/ambassador-tips-v1-tip/types';

export const presetMapper = ({
  settings,
  context,
  currency,
}: {
  settings: TipSettings;
  context: ActionsContext;
  currency: string;
}): Preset[] => {
  if (settings.tipType === TipType.UNKNOWN_TIP_TYPE) {
    return [];
  }

  const { flowAPI } = context;
  let presets: Preset[] = [];

  if (settings.presets?.length) {
    const zeroPreset: Preset = createPreset({
      tipPreset: '0',
      tipType: settings.tipType,
      flowAPI,
      currency,
    });

    presets = [
      zeroPreset,
      ...(settings.presets?.map((tipPreset: SettingsTipPreset) =>
        createPreset({
          tipPreset: `${tipPreset.value!}`,
          tipType: settings.tipType,
          flowAPI,
          currency,
        }),
      ) || []),
    ];
  }

  if (settings.allowCustomTip) {
    const customPreset = createPreset({
      tipPreset: '0',
      tipType: settings.tipType,
      presetType: PresetType.Custom,
      flowAPI,
      currency,
    });

    presets.push(customPreset);
  }

  return presets;
};

const createPreset = ({
  tipPreset,
  tipType,
  presetType = PresetType.Predefined,
  flowAPI,
  currency,
}: {
  tipPreset: string;
  tipType?: TipType;
  isSelected?: boolean;
  presetType?: PresetType;
  flowAPI: ControllerFlowAPI;
  currency: string;
}): Preset => {
  const currencyFormatter = flowAPI.getCurrencyFormatter({
    minimumIntegerDigits: 1,
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  let formattedValue = '';

  if (tipType === TipType.PERCENTAGE) {
    formattedValue = `${tipPreset}%`;
  } else {
    formattedValue = currencyFormatter({ currency, value: tipPreset });
  }

  const presetId = generateUUID();

  return {
    id: presetId,
    amount: `${tipPreset}`,
    amountType: tipTypeToAmountTypeMapper(tipType),
    type: presetType,
    formattedValue,
    isSelected: false,
  };
};

const tipTypeToAmountTypeMapper = (tipType?: TipType): AmountType => {
  if (tipType === TipType.PERCENTAGE) {
    return AmountType.Precentage;
  }

  return AmountType.Fixed;
};

export const mapSelectedPresetToUpdateTipRequest = ({
  selectedPreset,
  selectedAmount,
  purchaseFlowId,
}: {
  selectedPreset: Preset;
  selectedAmount: SelectedAmount;
  purchaseFlowId: string;
}): UpdateTipChoiceRequest => {
  const isCustom = selectedPreset.type === PresetType.Custom;

  const updateTipChoiceRequest: UpdateTipChoiceRequest = {
    purchaseFlowId: purchaseFlowId!,
    tipChoice: {
      tipType: amountTypeToTipTypeMapper(selectedAmount.type, isCustom),
      value: selectedAmount.amount,
    },
  };

  return updateTipChoiceRequest;
};

const amountTypeToTipTypeMapper = (
  amountType: AmountType,
  isCustom?: boolean,
): TipType => {
  if (isCustom) {
    return TipType.CUSTOM;
  }

  if (amountType === AmountType.Precentage) {
    return TipType.PERCENTAGE;
  }

  return TipType.AMOUNT;
};

export const getSelectedPreset = (presets: Preset[] = []): Preset =>
  presets.find((preset) => preset.isSelected)!;

export const getPresetById = (presets: Preset[] = [], id: string): Preset =>
  presets.find((preset) => preset.id === id)!;
