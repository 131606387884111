import { TipSettings } from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import {
  AmountType,
  ApplicationState,
  ApplicationStatus,
  Preset,
  PresetInfo,
  SelectedAmountStatuses,
} from '../../types';
import { GetCheckoutResponse } from '@wix/ambassador-ecom-v1-checkout/types';
import { getSelectedPreset, presetMapper } from '../presets';
import { ActionsContext } from '../../core/actionsContext/contextFactory';

export const createInitialState = async ({
  tipSettings,
  checkoutInfo,
  context,
}: {
  tipSettings?: TipSettings;
  checkoutInfo: GetCheckoutResponse;
  context: ActionsContext;
}): Promise<Partial<ApplicationState>> => {
  let presets: Preset[] = [];

  if (tipSettings?.allowCustomerTip) {
    presets = presetMapper({
      settings: tipSettings,
      context,
      currency: checkoutInfo.checkout?.currency!,
    });

    presets = await context.api.initialSelectedPreset({
      presets,
      tipSettings,
      purchaseFlowId: checkoutInfo?.checkout?.purchaseFlowId || '',
    });
  }

  const presetInfo: PresetInfo = { presets };

  const selectedPreset = getSelectedPreset(presets);

  return {
    status: ApplicationStatus.IDLE,
    checkoutInfo,
    presetInfo,
    selectedAmount: {
      amount: selectedPreset?.amount || '0',
      status: SelectedAmountStatuses.IDLE,
      type: selectedPreset?.amountType || AmountType.Fixed,
    },
  };
};
