import { SelectedAmount, SelectedAmountStatuses } from '../../types';
import _ from 'lodash';
import { CreateActionParams } from '../actions';
import { EventNames } from '../../core/fedops';
import { getSelectedPreset } from '../../utils';

export type SelectTipAmount = ({
  presetId,
  selectedAmount,
  debounceApi,
}: {
  presetId: string;
  debounceApi?: {
    wait: number;
  };
  selectedAmount: SelectedAmount;
}) => void;

let debounceSetPreset: ReturnType<typeof _.debounce> | undefined;

export function createSelectTipAmountAction({
  getControllerState,
  context,
}: CreateActionParams): SelectTipAmount {
  return async ({ presetId, selectedAmount, debounceApi }) => {
    const { fedopsLogger, flowAPI, api } = context;
    const { translations } = flowAPI;
    fedopsLogger?.interactionStarted(EventNames.SelectTipAmount);

    const [state, setState] = getControllerState();
    const { presets } = state.presetInfo!;
    const newPresets = presets?.map((preset) => ({
      ...preset,
      isSelected: preset.id === presetId,
    }));

    setState({
      presetInfo: { presets: newPresets },
      selectedAmount: {
        ...selectedAmount,
        status: debounceApi?.wait
          ? state.selectedAmount?.status
          : SelectedAmountStatuses.IN_PROGRESS,
      },
      errorMessage: undefined!,
    });

    const setPreset = async () => {
      try {
        if (debounceApi?.wait) {
          setState({
            selectedAmount: {
              ...selectedAmount,
              status: SelectedAmountStatuses.IN_PROGRESS,
            },
          });
        }

        const selectedPreset = getSelectedPreset(newPresets);

        await api.updateTip({
          selectedAmount,
          selectedPreset,
          purchaseFlowId: state.checkoutInfo?.checkout?.purchaseFlowId || '',
        });

        state.checkoutSlotProps?.refreshCheckoutCallback();

        setTimeout(() => {
          setState({
            selectedAmount: {
              ...selectedAmount,
              status: SelectedAmountStatuses.SUCCESS,
            },
          });
        }, 1500);

        fedopsLogger?.interactionEnded(EventNames.SelectTipAmount);
      } catch (e) {
        setState({
          selectedAmount: {
            ...selectedAmount,
            status: SelectedAmountStatuses.FAILURE,
          },
          errorMessage: translations.t(
            'app.wix-tips.widget.apply-tip-error-message',
          ),
        });
        setTimeout(() => {
          setState({
            selectedAmount: {
              ...selectedAmount,
              status: SelectedAmountStatuses.IDLE,
            },
          });
        }, 2000);
      }
    };

    if (debounceApi?.wait) {
      if (!debounceSetPreset) {
        debounceSetPreset = _.debounce(setPreset, debounceApi.wait);
      }
      await debounceSetPreset();
    } else {
      await setPreset();
    }
  };
}
