import { TipType } from '@wix/ambassador-tips-v1-tip/types';
import { TipSettings } from '@wix/ambassador-tips-settings-v1-tip-settings/types';
import { ApiContext } from '../../types';
import { Preset, PresetType } from '../../../types';

export type initialSelectedPresetArgs = {
  presets: Preset[];
  tipSettings: TipSettings;
  purchaseFlowId: string;
};

export const initialSelectedPreset = async ({
  presets,
  purchaseFlowId,
  tipSettings,
  api,
}: initialSelectedPresetArgs & ApiContext): Promise<Preset[]> => {
  let selectedPreset: Preset | undefined;

  const userSelection = await api.getUserSelectionFromCache({ purchaseFlowId });

  if (userSelection) {
    if (userSelection.tipType === TipType.CUSTOM) {
      selectedPreset = presets.find(
        (preset) => preset.type === PresetType.Custom,
      );

      if (selectedPreset) {
        selectedPreset.amount = userSelection.value || '0';
      }
    } else {
      selectedPreset = presets.find(
        (preset) => preset.amount === userSelection.value,
      );
    }
  } else {
    const defaultSettingPreset = tipSettings?.presets?.find?.(
      (preset) => preset.default,
    );

    selectedPreset = presets.find(
      (preset) => preset.amount === defaultSettingPreset?.value?.toString(),
    );

    if (!selectedPreset) {
      selectedPreset = presets.find((preset) => preset.amount === '0')!;
    }
  }

  const updatedPresets = presets.map((preset) => ({
    ...preset,
    isSelected: preset.id === selectedPreset?.id,
  }));

  return updatedPresets;
};
